/*jQuery(document).ready(function(){

    $('.dropdown #navbarDropdown').on("click",function(){
        document.location.href = $(this).attr('href');
    });

    if ($(window).width() > 992) {
        $("header .dropdown").hover(
            function() {
                $(this).addClass('show');
                $(this).addClass('active');
                var iddropdown = $(this).find('.nav-link').attr("class").substr( $(this).find('.nav-link').attr("class").lastIndexOf(' ') + 1);
                $('.dropdown-menu#'+iddropdown).fadeIn("fast");

            },
            function() {
                $(this).removeClass('show');
                $(this).removeClass('active');
                var iddropdown = $(this).find('.nav-link').attr("class").substr( $(this).find('.nav-link').attr("class").lastIndexOf(' ') + 1);
                $('.dropdown-menu#'+iddropdown).fadeOut("fast");
            });
        } else {
            $("header .nav-item i.more").click(function(){
                $(this).next(".dropdown-menu").slideToggle("fast");
            });
        }

});*/

/////////////
if ($(window).width() > 1201) {
    jQuery(document).ready(function(){
        //Megamenu
        var id = "";
        $('#navbarContent .nav-item.level1 a').mouseover(function() {
            var $this = $(this);
            $('#navbarContent .nav-item a.active').removeClass('active');
            $this.addClass('active');
            id = $this.attr('rel');
            if (id == null) {
                $('.widget-container').hide();
            }else {
                var $currentWidget = $('#' + id);
                $currentWidget.show().siblings('.widget-container').hide();
            }
            
        });
        $('header').mouseleave(function() {
            $('#navbarContent .nav-item a.active').removeClass('active');
            $('.widget-container').hide();
        });

        //Btn Demander un devis
        $('header .nav-item.level1#devis').css('right', $(window).width() - ($('.footer .container').offset().left + $('.footer .container').outerWidth())+'px');

    });
} else {
    jQuery(document).ready(function(){
        //Hamburger
        $('header .navbar-toggler-icon').click(function(){
            $( this ).toggleClass('close');
        });
        //Menu responsive
        $('header .nav-item.dropdown i.plus').click(function(){
            $( this ).hide();
            $( this ).next( "header .nav-item.dropdown i.moins" ).show();
            $(this).next().next('.dropdown-menu').show();
        });
        $('header .nav-item.dropdown i.moins').click(function(){
            $( this ).hide();
            $( this ).prev( "header .nav-item.dropdown i.plus" ).show();
            $(this).next('.dropdown-menu').hide();
        });
    });
}
$(window).resize(function(){
    if ($(window).width() > 1201) {
        jQuery(document).ready(function(){
            var id = "";
            $('#navbarContent .nav-item.level1 a').mouseover(function() {
                var $this = $(this);
                $('#navbarContent .nav-item a.active').removeClass('active');
                $this.addClass('active');
                id = $this.attr('rel');
                if (id == null) {
                    $('.widget-container').hide();
                }else {
                    var $currentWidget = $('#' + id);
                    $currentWidget.show().siblings('.widget-container').hide();
                }
                
            });
            $('header').mouseleave(function() {
                $('#navbarContent .nav-item a.active').removeClass('active');
                $('.widget-container').hide();
            });
        });
        
        //Btn Demander un devis
        $('header .nav-item.level1#devis').css('right', $(window).width() - ($('.footer .container').offset().left + $('.footer .container').outerWidth())+'px');

    } else {
        jQuery(document).ready(function(){
            $('header .nav-item.dropdown i').click(function(){
                $(this).next('.dropdown-menu').toggle();
            });
        });
    }
});
