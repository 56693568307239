//Imports fichiers js
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {}

require('./lazyload');
require('./friconix');
require('./HeaderSticky');
require('./FooterSticky');
require('./breadcrumb');
require('./BackToTop');

//Ancre scroll
$('a[href^="#"].ancre').click(function(){
    var the_id = $(this).attr("href");
    if (the_id === '#') {
        return;
    }
    $('html, body').animate({
        scrollTop:$(the_id).offset().top
    }, 'slow');
    return false;
});
